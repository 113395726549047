import { Link } from "react-router-dom"
import { pathMap } from "../../common"
import Banner from "../../components/Banner"
import Block from "../../components/Block"
import BlockTitle from "../../components/BlockTitle"
import ImgBox from "../../components/ImgBox"
import ItemList from "../../components/ItemList"
import Layout from "../../components/Layout"
import Page from "../../components/Page"

export default function RenLiZiYuan() {
    return <Page className="renLiZiYuan">
        <Banner url="http://dev-public.img.oss.shequbanjing.com/property/application/178f9643-589e-4454-b70a-50b6e53f68fe.png">
            <Banner.Title>人力资源平台</Banner.Title>
            <Banner.Description>一体化HR系统，应对企业人员流动频繁，入转调离协同效率低，薪酬考勤管理易出错等问<br />题，提供一站式人力资源管理解决方案，高效完成员工全生命周期信息化管理。</Banner.Description>
        </Banner>
        <Block className="block1">
            <BlockTitle>人力资源平台</BlockTitle>
            <ItemList className="a" style={{ marginTop: 45 }} >
                {[
                    {
                        bg: "https://dev-public.img.oss.shequbanjing.com/property/application/7c2adfd2-dba2-4be6-a447-859aaede7dd5.png",
                        title: "全员档案",
                        description: "员工档案、  人事异动、  试用转正、  人 事合同",
                    },
                    {
                        bg: "https://dev-public.img.oss.shequbanjing.com/property/application/f1d40909-acf0-4800-a076-32071f8e0611.png",
                        title: "精过程",
                        description: "员工奖励、  员工处罚、  内部调动、  考核薪资",
                    },
                    {
                        bg: "https://dev-public.img.oss.shequbanjing.com/property/application/31debd84-b259-4b1b-96b1-e08eae471a8b.png",
                        title: "成本控制",
                        description: "提高效率应对频繁的人员异动和组织 调整，通过强大的规则配置，联动考 勤、绩效、  薪资调整",
                    },
                    {
                        bg: "https://dev-public.img.oss.shequbanjing.com/property/application/ae8466a6-6e2a-48e0-8d01-4ea0d76cf5a3.png",
                        title: "计薪酬",
                        description: "落地假勤薪酬体系，实现智能核算 假勤、  薪资等，减少时间和人力成本",
                    },
                ].map((o, idx) => <ItemList.Item key={idx} backgroundImage={o.bg} >
                    <ItemList.Title>{o.title}</ItemList.Title>
                    <ItemList.Text>{o.description}</ItemList.Text>
                </ItemList.Item>)}
            </ItemList>
            <ItemList className="b" style={{ marginTop: 54 }}>
                {[
                    {
                        icon: require("./imgs/block1/1.png"),
                        title: "人员招聘",
                    },
                    {
                        icon: require("./imgs/block1/2.png"),
                        title: "员工档案",
                    },
                    {
                        icon: require("./imgs/block1/3.png"),
                        title: "试用转正",
                    },
                    {
                        icon: require("./imgs/block1/4.png"),
                        title: "员工奖励",
                    },
                    {
                        icon: require("./imgs/block1/5.png"),
                        title: "薪酬管理",
                    },
                    {
                        icon: require("./imgs/block1/6.png"),
                        title: "内部调动",
                    },
                    {
                        icon: require("./imgs/block1/7.png"),
                        title: "薪资考核",
                    },
                    {
                        icon: require("./imgs/block1/8.png"),
                        title: "人事管理",
                    },
                    {
                        icon: require("./imgs/block1/9.png"),
                        title: "考勤管理",
                    },
                ].map((o, idx) => <ItemList.Item key={idx}>
                    <ItemList.Icon url={o.icon} />
                    <ItemList.Title>{o.title}</ItemList.Title>
                </ItemList.Item>)}
            </ItemList>
        </Block>
        <Block className="block2">
            <BlockTitle>招聘管理</BlockTitle>
            <Layout type="LR" style={{ marginTop: 46 }}>
                <Layout.Left width={450} >
                    <ImgBox style={{ width: 330 }} rate={1.063636} url={require("./imgs/block2/view.png")} />
                </Layout.Left>
                <Layout.Right>
                    <div className="content">
                        <ul className="ul-description">
                            <li>建立与组织目标相一致的人才需求计划</li>
                            <li>拓宽企业人才供应渠道，实现组织内外部人才共享</li>
                            <li>构建便捷高效的招聘流程体系，提高工作效率</li>
                            <li>引进先进的人才测评技术帮助企业甄选人才</li>
                            <li>关注应聘人员过程体验，为应聘人员和用人部门提供满意的服务</li>
                            <li>建立集团总部对各级组织招聘计划执行与进度的监控体系，提升集团管控能力</li>
                        </ul>
                        <Link to={`/${pathMap.shengTaiYuHeZuo}`} target="_blank"><div className="btn" style={{ marginLeft: 20 }} >立即咨询</div></Link>

                    </div>
                </Layout.Right>
            </Layout>
        </Block>
        <Block className="block3">
            <BlockTitle>人力资源</BlockTitle>
            <ItemList card>
                {[
                    {
                        img: "http://dev-public.img.oss.shequbanjing.com/property/application/b51bdfe4-15a3-48e4-92bc-022b88bcb5f7.png",
                        title: "找到更合适的人",
                        description: <>
                            · 招聘的全周期管理<br />
                            · 统一的岗位需求库 <br />
                            · 多渠道的岗位发布<br />
                            · 人才信息的几种管理
                        </>
                    },
                    {
                        img: "http://dev-public.img.oss.shequbanjing.com/property/application/e7273dd5-fe8b-4568-b5db-a0cc69a6e023.png",
                        title: "让员工在工作中成长",
                        description: <>
                            · 多样化的培训方式<br />
                            · 融入工作的培训体系<br />
                            · 员工学习成长地图<br />
                            · 员工工作情况的监控
                        </>
                    },
                    {
                        img: "http://dev-public.img.oss.shequbanjing.com/property/application/b244cb6e-86e8-4fc2-bfd7-902d56745662.png",
                        title: "让有能力的人持续可晋升",
                        description: <>
                            · 员工异动的提醒与预警<br />
                            · 灵活的薪酬计算体系<br />
                            · 绩效核算的准确与及时<br />
                            · 人员考核的公证、客观
                        </>
                    },
                    {
                        img: "http://dev-public.img.oss.shequbanjing.com/property/application/bf46bbe1-a3da-4cce-9a9b-37f379a3e5ba.png",
                        title: "事务处理全程数字化",
                        description: <>
                            · 人员关系的流程化管理<br />
                            · 移动端全场景自助<br />
                            · 人员信息的全面展示<br />
                            · 合同的全程数字化签署
                        </>
                    },
                ].map((o, idx) => <ItemList.Item key={idx}>
                    <ItemList.Img url={o.img} />
                    <ItemList.Title>{o.title}</ItemList.Title>
                    <ItemList.Text>{o.description}</ItemList.Text>
                </ItemList.Item>)}
            </ItemList>
        </Block>
        <Block className="block4">
            <BlockTitle>培训管理</BlockTitle>
            <Layout type="LR" style={{ marginTop: 46 }}>
                <Layout.Left width={450} >
                    <ImgBox style={{ width: 330 }} rate={1.063636} url={require("./imgs/block4/view.png")} />
                </Layout.Left>
                <Layout.Right>
                    <div className="content">
                        <ul className="ul-description">
                            <li>建立与组织目标相一致的人才需求计划</li>
                            <li>拓宽企业人才供应渠道，实现组织内外部人才共享</li>
                            <li>构建便捷高效的招聘流程体系，提高工作效率</li>
                            <li>引进先进的人才测评技术帮助企业甄选人才</li>
                            <li>关注应聘人员过程体验，为应聘人员和用人部门提供满意的服务</li>
                            <li>建立集团总部对各级组织招聘计划执行与进度的监控体系，提升集团管控能力</li>
                        </ul>
                        <Link to={`/${pathMap.shengTaiYuHeZuo}`} target="_blank"><div className="btn" style={{ marginLeft: 20 }}>立即咨询</div></Link>
                    </div>
                </Layout.Right>
            </Layout>
        </Block>
        <Block className="block5">
            <BlockTitle>薪酬管理</BlockTitle>
            <Layout type="LR" style={{ marginTop: 46 }} >
                <Layout.Right>
                    <div className="content">
                        <div className="description">
                            基本薪资、绩效、福利、津贴、补贴、奖金等多种薪资项目均可
                            灵活组合;精细化核算员工薪酬，补发补扣，离职结薪等复杂场景
                            均可支持，更能直接引入外部数据参与算薪，让算薪更准确更高效;
                            集团、公司随时了解团队薪酬分布及调薪历史，快速调整团队人员
                            薪酬，让薪资更公平，助力团队人才保留;支持一键输出薪资报表，
                            满足企业按项目、按行政等各个维度汇报的需求;
                        </div>
                        <Link to={`/${pathMap.shengTaiYuHeZuo}`} target="_blank"><div className="btn">立即咨询</div></Link>
                    </div>
                </Layout.Right>
                <Layout.Left width={450} >
                    <ImgBox style={{ width: 330 }} rate={1.063636} url={require("./imgs/block5/view.png")} />
                </Layout.Left>
            </Layout>
        </Block>
    </Page>
}