import { CSSProperties, ReactNode } from "react"

function ItemList(props: { card?: boolean; style?: CSSProperties; className?: string; children: ReactNode }) {
    return <div className={`component-itemList ${props.card ? "card" : ""} ${props.className || ""}`.trim()} style={props.style} >{props.children}</div>
}

function Item(props: {
    style?: CSSProperties;
    backgroundImage?: string | NodeRequire;
    className?: string;
    children: ReactNode;
    onClick?(): void;
}) {
    return <div
        className={`item ${props.className || ""}`.trim()}
        style={{ ...props.style, backgroundImage: props.backgroundImage && `url(${props.backgroundImage})` }}
        onClick={props.onClick} >{props.children}</div>
}

function Icon(props: { url: any }) {
    return <div className="itemIcon" style={{ backgroundImage: `url(${props.url})` }} />
}

function Img(props: { url: any }) {
    return <div className="itemImg" style={{ backgroundImage: `url(${props.url})` }} />
}

function Title(props: { children: ReactNode }) {
    return <div className="itemTitle">{props.children}</div>
}

function Text(props: { children: ReactNode }) {
    return <div className="itemText">{props.children}</div>
}

export default Object.assign(ItemList, { Item, Icon, Title, Text, Img });