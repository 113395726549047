import Banner from "../../components/Banner";
import Block from "../../components/Block";
import ImgBox from "../../components/ImgBox";
import Layout from "../../components/Layout";
import Page from "../../components/Page";

export default function News3() {
    return <Page className="pingPaiDongTai-news">
        <Banner url={require("./imgs/banner/bg2.png")} />
        <Block className="block">
            <div className="title">社区半径签约上市物业集团 提供智慧物业云平台系统</div>
            <div className="p">
                2019年9月4日，国内领先的智慧社区解决方案提供商-北京社区半径信息技术有限公司，与香港联交所上市物业集团-和泓服务集团，正式签署了“和泓服
                务.智社区云平台合作协议”。
            </div>
            <Layout type="LR">
                <Layout.Left width="100%">
                    <ImgBox style={{ width: 415 }} rate={0.66747} url={require("./imgs/news3/1.png")} />
                </Layout.Left>
            </Layout>
            <div className="p">
                和泓服务集团是国内领先的物业管理企业。管理项目覆盖国内11个省市近千万平米，在2019年中指院物业百强排名中荣膺第44名。2019年7月12日，和泓服
                务顺利登陆香港股票交易所（代码6093HK），成为了内地第15家登陆资本市场的物业公司，并得到了市场的充分认可。据悉，和泓服务集团在上市以后，主要
                目标将放在快速拓展管理面积，优化内部管理机制及提升集团运营利润上。为此，和泓服务将内部管理的信息化、移动化改造，及管理物业项目的数字化、智能
                化改造，放在了重要的位置。本次与社区半径的合作，也是看中了社区半径在国内智慧物业管理平台方面丰富的经验及优异的前瞻性。希望通过智慧社区云平台
                系统的搭建，帮助和泓服务通过科技的方式对于公司的管理流程、工作效率、品质监控、数据分析、业主服务以及智能物联网设备的接入上实现突破。
            </div>
            <Layout type="LR">
                <Layout.Left width="100%">
                    {/* <ImgBox style={{ width: 888 }} rate={0.563063} url={require("./imgs/news3/2.png")} /> */}
                    <ImgBox style={{ width: 888 }} rate={0.563063} url="https://public.img.oss.shequbanjing.com/property/application/6f36f73e-54ce-4a84-9e61-95f66cf78c2e.png" />
                </Layout.Left>
            </Layout>
            <div className="p">
                社区半径在2018年与和泓服务集团合作，为和泓服务集团位于北京市丰台区的彩虹城小区打造了智慧社区项目试点。据了解，彩虹城智慧社区项目自2018
                年12月交付以来，通过整体的智能化改造，在降本增效上实现了很大的突破。通过彩虹城一区二区的停车道闸改造， 2019年上半年临停车收入增加了12万元；
                通过将彩虹城一、二、四区原有水泵改造为无负压变频水泵，每月节电达到60%以上，每年综合节省能源及运维成本6-7万元；通过设备设施自动巡检设备的安
                装，每年节约设备巡检人员成本15万元，并提升了设备设施监控的可靠性。此外，人脸识别门禁、无线烟感、监控系统、人脸打卡机、运营数据大屏、居民APP
                等一整套智慧系统的部署，也使得物业管理效率、多种经营的开展、小区的安防指数及业主居住感受等方面得到了很大的提升，并得到了前来参观的物协领导及
                全国多地物业同行的称赞。
            </div>
            <div className="p">
                基于之前的成功合作，社区半径本次与和泓服务集团再度携手，为和泓服务集团定制开发“和泓服务.智社区云平台”。根据协议约定，社区半径将为和泓服
                务集团提供一套结合和泓服务自身物业管理流程标准化体系的物业信息管理系统，助力上市之后的和泓服务集团，打造智慧化信息化的新型物业管理服务体系。
            </div>
        </Block>
    </Page>
}