import axios from "axios";

type Path = "home" | "about";

export type CommonProps = {
    path: Path;
    visible: boolean;
    jumpTo(path: Path): void
};

export function submit({ name, phone, job, company, email, remark }: any) {
    if (!name) {
        return alert("请填写姓名");
    }
    if (!phone) {
        return alert("请填写电话");
    }
    return axios.post(
        `https://portal.sqbj.com/gc/h5/contact/add`,
        {
            name,
            phone,
            profession: job,
            company,
            email,
            content: remark,
            source: "sqbj"
        },
        {
            headers: {
                "Content-Type": "application/json"
            }
        }
    );
}

export function jump2page(path: string) {
    window.open(path, '_blank');
}