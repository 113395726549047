import { useState } from "react"
import Banner from "../../components/Banner"
import Block from "../../components/Block";
import FlexBox from "../../components/FlexBox";
import Page from "../../components/Page";
import { submit } from "../api";

export default function ShengTaiYuHeZuo() {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [job, setJob] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");
    const [remark, setRemark] = useState("");

    return <Page className="shengTaiYuHeZuo">
        <Banner url={require("./imgs/banner/bg.png")}>
            <Banner.Title>与我们一起<br />共筑智慧社区生态</Banner.Title>
            <Banner.Description>释放智慧社区的无限潜力</Banner.Description>
        </Banner>
        <FlexBox>
            <Block noFlex className="block">
                <div className="tips">
                    <div>提交您的信息</div>
                    <div>我们会有专人联系您</div>
                </div>
                <div className="form">
                    <div className="formItem">
                        <input
                            placeholder="姓名（必填）"
                            onChange={e => setName(e.target.value.trim())} />
                    </div>
                    <div className="formItem">
                        <input
                            placeholder="电话（必填）"
                            onChange={e => setPhone(e.target.value.trim())} />
                    </div>
                    <div className="formItem">
                        <input
                            placeholder="职业（非必填）"
                            onChange={e => setJob(e.target.value)} />
                    </div>
                    <div className="formItem">
                        <input
                            placeholder="公司名称（非必填）"
                            onChange={e => setCompany(e.target.value)} />
                    </div>
                    <div className="formItem">
                        <input
                            placeholder="邮箱（非必填）"
                            onChange={e => setEmail(e.target.value)} />
                    </div>
                    <div className="formItem" style={{ visibility: "hidden" }} />
                    <div className="formItem large">
                        <textarea
                            placeholder="请告诉我们如何提供帮助（非必填项）"
                            onChange={e => setRemark(e.target.value)} />
                    </div>
                </div>
                <div className="submitBtn" onClick={async () => {
                    try {
                        await submit({
                            name,
                            phone,
                            profession: job,
                            company,
                            email,
                            content: remark,
                        });
                        alert("提交成功");
                        setName("");
                        setPhone("");
                        setJob("");
                        setCompany("");
                        setEmail("");
                        setRemark("");
                    } catch (e: any) {
                        alert(e.message);
                    }
                }} >提交</div>
            </Block>
        </FlexBox>
    </Page>
}