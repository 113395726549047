import { Component, ReactNode } from "react"
import { Link } from "react-router-dom";
import { pathMap } from "../../common";
import Block from "../../components/Block";
import BlockDescription from "../../components/BlockDescription";
import BlockTitle from "../../components/BlockTitle";
import FlexBox from "../../components/FlexBox";
import ImgBox from "../../components/ImgBox";
import ItemList from "../../components/ItemList";
import Page from "../../components/Page";
import { jump2page, submit } from "../api";

function Banner(props: { in?: boolean; out?: boolean; bgUrl: string; children: ReactNode; }) {
    return <div className={`item ${props.in ? "in" : ""} ${props.out ? "out" : ""}`.trim()}>
        <div className="bg" style={{ backgroundImage: `url(${props.bgUrl})` }} />
        <div className="content"><FlexBox>{props.children}</FlexBox></div>
    </div>
}

export default class Home extends Component<{}, {
    currIdx: number;
    name: string;
    phone: string;
}> {

    private timeoutIndex: any;
    private stopSwitch = false;

    constructor(props: any) {
        super(props);
        this.state = {
            currIdx: 0,
            name: "",
            phone: "",
        }
    }

    componentDidMount(): void {
        this.update();
    }

    render() {
        const { currIdx, name, phone } = this.state;
        const preIdx = (currIdx - 1) < 0 ? 3 : currIdx - 1;
        return <Page className="home">
            <div className="banner" onMouseEnter={() => {
                this.stopSwitch = true;
                clearTimeout(this.timeoutIndex);
            }} onMouseLeave={() => {
                this.stopSwitch = false;
                this.switch(currIdx);
            }} >
                {[
                    {
                        content: <>
                            <div className="titleImg" style={{ backgroundImage: "url(http://dev-public.img.oss.shequbanjing.com/property/application/c27e8553-33ef-4d80-9706-afa9a582c7ff.png)" }} />
                            <div className="description">物业场景科学化、精细化、智能化管理<br />助力物业数字化转型</div>
                        </>,
                        bgUrl: require("./imgs/banner/1.png"),
                    },
                    {
                        content: <>
                            <div className="appLogo" style={{ backgroundImage: `url(${require("./imgs/banner/logo.png")})` }} />
                            <div className="title" style={{ marginTop: 20 }}>让社区生活更简单</div>
                            <div className="description">重塑业主与物业&nbsp;人与社区的关系<br />专注用户社区生活提供更多便利和乐趣</div>
                        </>,
                        bgUrl: require("./imgs/banner/2.png"),
                    },
                    {
                        content: <>
                            <div className="title">简单操作&nbsp;一键升级<br />物业管理更轻松</div>
                            <div className="btn">免费试用</div>
                        </>,
                        bgUrl: require("./imgs/banner/3.png"),
                    },
                    {
                        content: <>
                            <div className="title">十大平台<br />三化三管</div>
                            <div className="btn" onClick={() => jump2page(`/${pathMap.shengTaiYuHeZuo}`)}>立即咨询</div>
                        </>,
                        bgUrl: require("./imgs/banner/4.png"),
                    }
                ].map((o, idx) => <Banner key={idx} in={currIdx === idx} out={preIdx === idx} bgUrl={o.bgUrl}>{o.content}</Banner>)}
                <div className="switchBar-box">
                    {[0, 1, 2, 3].map(o => <div key={o} className={`switchBar ${currIdx === o ? "active" : ""}`.trim()} onClick={() => this.switch(o)} />)}
                </div>
            </div>
            <Block className="block1">
                <BlockTitle>十大平台</BlockTitle>
                <BlockDescription>智慧物业涵盖十大解决平台，为合伙伙伴全面赋能，提升管理效率</BlockDescription>
                <ItemList>
                    {[
                        {
                            url: require("./imgs/block1/1.png"),
                            title: "物业管理平台",
                        },
                        {
                            url: require("./imgs/block1/2.png"),
                            title: "财务收费平台",
                        },
                        {
                            url: require("./imgs/block1/3.png"),
                            title: "合同管理平台",
                        },
                        {
                            url: require("./imgs/block1/4.png"),
                            title: "费用报销平台",
                        },
                        {
                            url: require("./imgs/block1/5.png"),
                            title: "物料管理平台",
                        },
                        {
                            url: require("./imgs/block1/6.png"),
                            title: "智慧大屏平台",
                        },
                        {
                            url: require("./imgs/block1/7.png"),
                            title: "人力资源平台",
                        },
                        {
                            url: require("./imgs/block1/8.png"),
                            title: "OA审批平台",
                        },
                        {
                            url: require("./imgs/block1/9.png"),
                            title: "智慧物联平台",
                        },
                        {
                            url: require("./imgs/block1/10.png"),
                            title: "手机看板平台",
                        },
                    ].map((o, idx) => <ItemList.Item key={idx} >
                        <ItemList.Icon url={o.url} />
                        <ItemList.Title>{o.title}</ItemList.Title>
                    </ItemList.Item>)}
                </ItemList>
                <div className="btns">
                    <Link to={`/${pathMap.shengTaiYuHeZuo}`} target="_blank"><div className="btn primary" >立即咨询</div></Link>
                    <Link to="http://demo.portal.sqbj.com" target="_blank"><div className="btn">免费试用</div></Link>
                </div>
            </Block>
            <Block className="block2" backgroundImage={require("./imgs/block2/bg.png")}>
                <BlockTitle>解决方案</BlockTitle>
                <BlockDescription>智慧物业多场景应用全面开放 带领合作伙伴全面进入数字化时代</BlockDescription>
                <ItemList card >
                    {[
                        {
                            bgUrl: require("./imgs/block2/bg1.png"),
                            iconUrl: require("./imgs/block2/icon1.png"),
                            title: <>智慧物业<br />解决方案</>,

                        },
                        {
                            bgUrl: require("./imgs/block2/bg2.png"),
                            iconUrl: require("./imgs/block2/icon2.png"),
                            title: <>智慧园区<br />解决方案</>,

                        },
                        {
                            bgUrl: require("./imgs/block2/bg3.png"),
                            iconUrl: require("./imgs/block2/icon3.png"),
                            title: <>智慧校园<br />解决方案</>,

                        },
                        {
                            bgUrl: require("./imgs/block2/bg4.png"),
                            iconUrl: require("./imgs/block2/icon4.png"),
                            title: <>人力资源<br />解决方案</>,

                        },
                        {
                            bgUrl: require("./imgs/block2/bg5.png"),
                            iconUrl: require("./imgs/block2/icon5.png"),
                            title: <>智慧社区<br />解决方案</>,

                        },
                    ].map((o, idx) => <ItemList.Item key={idx} backgroundImage={o.bgUrl} >
                        <ItemList.Icon url={o.iconUrl} />
                        <ItemList.Title>{o.title}</ItemList.Title>
                    </ItemList.Item>)}
                </ItemList>
            </Block>
            <Block className="block3">
                <BlockTitle>合作伙伴</BlockTitle>
                <BlockDescription>最懂企业产业数字化转型的专家和客户伙伴，用专业完成客户托付。</BlockDescription>
                <div className="content">
                    <ImgBox rate={0.366599} url={require("./imgs/block3/view.png")} />
                    <div className="title">让天下的物业公司实现数智化升级</div>
                    <div className="description">社区半径致力于通过物联网、大数据、云计算、AI人工智能等先进的理念、技术及产品，提供基于智慧物业的整体解决方案，为物业、地产、园区、街道、政府等进行智慧化改造及升级。服务项目超过5000个，为合伙伙伴提供智慧、安全、高效、开放的智慧管理平台，为业主提供平安、舒适、宜居、便捷的生活居住体验</div>
                </div>
            </Block>
            <Block className="block4" backgroundImage={require("./imgs/block4/bg.png")}>
                <div className="content">
                    <div className="title">联系我们<br />让您的物业公司实现数字化升级</div>
                    <div className="form">
                        <input
                            placeholder="姓名"
                            value={name}
                            onChange={e => this.setState({ name: e.target.value })} />
                        <input
                            className="large"
                            placeholder="手机号"
                            value={phone}
                            onChange={e => this.setState({ phone: e.target.value })} />
                        <div className="btn" style={{ marginLeft: 12 }} onClick={async () => {
                            try {
                                await submit({ name, phone });
                                this.setState({ name: "", phone: "" });
                            } catch (e: any) {
                                alert(e.message);
                            }
                        }} >获取方案</div>
                    </div>
                </div>
            </Block>
        </Page>
    }

    private switch = (idx: number) => {
        clearTimeout(this.timeoutIndex);
        this.setState({ currIdx: idx }, () => {
            if (!this.stopSwitch) {
                this.update();
            }
        });
    }

    private update = () => {
        this.timeoutIndex = setTimeout(() => {
            let idx = this.state.currIdx + 1;
            if (idx > 3) {
                idx = 0;
            }
            this.setState({ currIdx: idx });
            this.update();
        }, 5000)
    }
}