import { CSSProperties, useEffect, useState } from "react";

type Props = {
    style?: CSSProperties;
    className?: string;
    type?: "img" | "bg";
    rate: number;
    url: string;
};

export default function ImgBox(props: Props) {
    const [dom, setDom] = useState<any>();
    const [height, setHeight] = useState(0);
    useEffect(() => {
        if (dom) {
            const style = getComputedStyle(dom);
            const width = parseInt(style.width);
            setHeight(width * props.rate);
        }
    }, [dom, props.rate]);
    const className = [props.className ? ` ${props.className}` : ""];
    if (props.type === "bg") {
        return <div className={`component-bgBox${className}`} ref={ref => setDom(ref)} style={{ ...props.style, height, backgroundImage: `url(${props.url})` }} />
    } else {
        return <img className={`component-imgBox${className}`} ref={ref => setDom(ref)} style={{ ...props.style, height }} src={props.url} alt="" />
    }

}