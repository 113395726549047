import { Fragment, useState } from "react"
import { Link } from "react-router-dom"
import { pathMap } from "../../common"
import Banner from "../../components/Banner"
import Block from "../../components/Block"
import BlockTitle from "../../components/BlockTitle"
import ImgBox from "../../components/ImgBox"
import ItemList from "../../components/ItemList"
import Layout from "../../components/Layout"
import Page from "../../components/Page"

export default function WuLianWangJieJueFangAn() {
    const [currIdx, setCurrIdx] = useState(0);
    return <Page className="wuLianWangJieJueFangAn">
        <Banner url="https://dev-public.img.oss.shequbanjing.com/property/application/bd718ff6-3bef-4c58-8ebe-f90382e3da70.png">
            <Banner.Title>物联网解决方案</Banner.Title>
            <Banner.Description>物联网解决方案通过对云计算、大数据、人工智能、物联网等技术的深度整合，借助云产品<br />构建创新型应用，以便搜集、处理、分析联网设备产生的数据并据此采取行动</Banner.Description>
        </Banner>
        <Block className="block1">
            <BlockTitle>社区半径智能物联框架</BlockTitle>
            <ImgBox style={{ marginTop: 25 }} rate={0.353368} url="http://dev-public.img.oss.shequbanjing.com/property/application/e5f0af93-1c22-45d8-b7f0-73a5b7516dee.png" />
        </Block>
        <Block className="block2">
            <BlockTitle>智能车行</BlockTitle>
            <ItemList card className="a" style={{ marginTop: 30 }} >
                <ItemList.Item >
                    <ItemList.Icon url={require("./imgs/block2/1.png")} />
                    <ItemList.Title>智慧通行</ItemList.Title>
                    <ItemList.Text>AI识别车牌，智能算法，<br />识别率&lt;99.3%</ItemList.Text>
                </ItemList.Item>
                <ItemList.Item>
                    <ItemList.Icon url={require("./imgs/block2/2.png")} />
                    <ItemList.Title>智能支付</ItemList.Title>
                    <ItemList.Text>线上线下缴费方式，收费<br />实时到达指定账户</ItemList.Text>
                </ItemList.Item>
                <ItemList.Item>
                    <ItemList.Icon url={require("./imgs/block2/3.png")} />
                    <ItemList.Title>智能客服</ItemList.Title>
                    <ItemList.Text>撤除门岗，人员归集，<br />业务集中管控</ItemList.Text>
                </ItemList.Item>
                <ItemList.Item>
                    <ItemList.Icon url={require("./imgs/block2/4.png")} />
                    <ItemList.Title>智能支持</ItemList.Title>
                    <ItemList.Text>双向对讲，<br />出入口自主通行</ItemList.Text>
                </ItemList.Item>
            </ItemList>
            <ItemList card className="b" style={{ marginTop: 43 }}>
                <ItemList.Item>
                    <ItemList.Img url={"http://dev-public.img.oss.shequbanjing.com/property/application/9a7ccab2-b8d9-4282-80ff-bbdb92f26349.png"} />
                    <ItemList.Text>
                        ・出入通行不畅<br />
                        ・维护成本高<br />
                        ・车场运营联动问题多<br />
                        ・跑冒滴漏无法管理
                    </ItemList.Text>
                </ItemList.Item>
                <ItemList.Item>
                    <ItemList.Img url="http://dev-public.img.oss.shequbanjing.com/property/application/fa1eb7d3-b3c8-4db5-9732-d2e3dfb41084.png" />
                    <ItemList.Text>
                        ・通行迅速，智能识别各种车辆，自动扣费<br />
                        ・模块化标准设备，易安装维护<br />
                        ・无人值守，远程处理异常<br />
                        ・业务联动助益运营效率<br />
                        ・线上线下收费一体化，效率翻倍
                    </ItemList.Text>
                </ItemList.Item>
            </ItemList>
        </Block>
        <Block className="block3">
            <BlockTitle>智能设备监测</BlockTitle>
            <ItemList style={{ marginTop: 30 }}>
                {[
                    "突破地域限制", "提高运维效率", "自主运行", "故障/告警秒级触达", "降低设备巡检成本"
                ].map((o, idx) => <ItemList.Item key={idx} className={currIdx === idx ? "active" : ""} onClick={() => setCurrIdx(idx)} >{o}</ItemList.Item>)}
            </ItemList>
            <Layout type="LR" style={{ marginTop: 20 }}>
                {[
                    {
                        img: require("./imgs/block3/1.png"),
                        content: <>
                            <div className="title">突破地域限制</div>
                            <div className="text">
                                •  集团到项目逐级渗透多城市多项目<br />
                                •  远程实时监测，提升集团管理效率
                            </div>
                            <Link to={`/${pathMap.shengTaiYuHeZuo}`} target="_blank"><div className="btn">立即咨询</div></Link>
                        </>
                    },
                    {
                        img: require("./imgs/block3/2.png"),
                        content: <>
                            <div className="title">提高运维效率</div>
                            <div className="text">
                                •  报警信息自动生成工单推送、在线巡检<br />
                                •  降低信息流转成本
                            </div>
                            <Link to={`/${pathMap.shengTaiYuHeZuo}`} target="_blank"><div className="btn">立即咨询</div></Link>
                        </>
                    },
                    {
                        img: require("./imgs/block3/3.png"),
                        content: <>
                            <div className="title">自主运行</div>
                            <div className="text">
                                •  设备监测、巡检、异常监测自运行<br />
                                •  降低对人员的专业性要求<br />
                                •  故障留存可追溯，数据分析辅助决策
                            </div>
                            <Link to={`/${pathMap.shengTaiYuHeZuo}`} target="_blank"><div className="btn">立即咨询</div></Link>
                        </>
                    },
                    {
                        img: require("./imgs/block3/4.png"),
                        content: <>
                            <div className="title">故障/告警秒级触达</div>
                            <div className="text">故障响应时间/问题确认时间从10分钟降到秒级，及时发现隐患，减少损失</div>
                            <Link to={`/${pathMap.shengTaiYuHeZuo}`} target="_blank"><div className="btn">立即咨询</div></Link>
                        </>
                    },
                    {
                        img: require("./imgs/block3/5.png"),
                        content: <>
                            <div className="title">降低设备巡检成本</div>
                            <div className="text">
                                •  巡检耗时降低 70%~80%<br />
                                •  人员配置减少 30%左右
                            </div>
                            <Link to={`/${pathMap.shengTaiYuHeZuo}`} target="_blank"><div className="btn">立即咨询</div></Link>
                        </>
                    },
                ].map((o, idx) => currIdx === idx && <Fragment key={idx} >
                    <Layout.Left width={456}>
                        <ImgBox rate={0.584444} style={{ width: 450 }} url={o.img} />
                    </Layout.Left>
                    <Layout.Right style={{ backgroundImage: "url(http://dev-public.img.oss.shequbanjing.com/property/application/31f75544-fede-4352-9627-9c083a4136ff.png)" }}>
                        <div className="content">{o.content}</div>
                    </Layout.Right>
                </Fragment>)}
            </Layout>
        </Block>
        <Block className="block4">
            <BlockTitle>智能人行</BlockTitle>
            <ItemList card className="a">
                {[
                    {
                        flex: 1,
                        icon: require("./imgs/block4/1.png"),
                        title: "高效通行",
                        description: <>人脸/扫码/卡片/蓝牙/远程，多种开门<br />方式，满足不同场景通行需求</>
                    },
                    {
                        flex: 1.080906,
                        icon: require("./imgs/block4/2.png"),
                        title: "访客管理",
                        description: <>线上线下缴访客扫码通行，无需登记，简单方便；<br />通行记录云端存储，有迹可循，安全可控</>
                    },
                    {
                        flex: 1,
                        icon: require("./imgs/block4/3.png"),
                        title: "数据管理",
                        description: <>业主、访客、租户、物管、商家服务人<br />员等不同人员类型的数据线上管理</>
                    },
                ].map((o, idx) => <ItemList.Item key={idx} style={{ flex: o.flex }}>
                    <ItemList.Icon url={o.icon} />
                    <ItemList.Title>{o.title}</ItemList.Title>
                    <ItemList.Text>{o.description}</ItemList.Text>
                </ItemList.Item>)}
            </ItemList>
            <ItemList card className="b">
                {[
                    {
                        img: "http://dev-public.img.oss.shequbanjing.com/property/application/e68382ce-d603-4518-bdfd-4ce3150b3be9.png",
                        title: "高效化",
                        description: <>多种通行方式满足不同类型<br />人群出入</>
                    },
                    {
                        img: "http://dev-public.img.oss.shequbanjing.com/property/application/0b30dffa-890a-4317-abd4-22f093c70f58.png",
                        title: "实时化",
                        description: <>设备故障主动报警，信息实时<br />核查</>
                    },
                    {
                        img: "http://dev-public.img.oss.shequbanjing.com/property/application/262c0f12-81a0-41d4-81e4-615dce2d773f.png",
                        title: "数据化",
                        description: <>通行权限、数据、设备，线上<br />数据管理</>
                    },
                    {
                        img: "http://dev-public.img.oss.shequbanjing.com/property/application/e0d1944c-9a5b-4161-850e-61e5f3c34fc3.png",
                        title: "智能化",
                        description: <>设备智能化，高可用、低<br />维护，降低维护成本</>
                    },
                ].map((o, idx) => <ItemList.Item key={idx}>
                    <ItemList.Img url={o.img} />
                    <ItemList.Title>{o.title}</ItemList.Title>
                    <ItemList.Text>{o.description}</ItemList.Text>
                </ItemList.Item>)}
            </ItemList>
        </Block>
    </Page>
}