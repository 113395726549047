import React, { CSSProperties, ReactNode } from "react";
import Header from "./Header";

type BannerProps = {
    url?: string;
    children?: React.ReactNode;
};

function Banner(props: BannerProps) {
    return <div className="component-banner" style={{ backgroundImage: props.url && `url(${props.url})` }}>
        <Header />
        <div className="content">{props.children}</div>
    </div>
}

type TitleProps = { style?: CSSProperties; children: ReactNode };

function Title(props: TitleProps) {
    return <div className="title" style={props.style} >{props.children}</div>
}

type DescriptionProps = { style?: CSSProperties; children: ReactNode };
function Description(props: DescriptionProps) {
    return <div className="description" style={props.style} >{props.children}</div>
}

export default Object.assign(Banner, { Title, Description });