import Layout from "./Layout";

export default function Footer() {
    return <div className="component-footer">
        {/* <div className="friendsLink">
            <div className="left">友情链接</div>
            <div className="right">
                <a target="_blank" rel="noreferrer" href="http://www.ecpmi.org.cn">中国物业管理协会</a>
                <a target="_blank" rel="noreferrer" href="http://120.55.118.116:9080">北京物业管理行业协会</a>
                <a target="_blank" rel="noreferrer" href="http://www.szpmi.org">深圳物业管理行业协会</a>
                <a target="_blank" rel="noreferrer" href="https://www.mjsfax.com">民生金服</a>
                <a target="_blank" rel="noreferrer" href="https://www.izhaohu.com/thumb.html">爱照护</a>
                <a target="_blank" rel="noreferrer" href="http://www.donghuigroup.cc/h-col-115.html">东汇集团</a>
                <a target="_blank" rel="noreferrer" href="http://www.justbon.com.cn">嘉宝股份</a>
            </div>
        </div> */}
        <div className="divider" />
        <div className="layout">
            <div className="left">
                <div className="itemList">
                    <div className="item">
                        <div className="label">关于我们</div>
                        <div className="content">客服热线（工作时间9:30-18:30）</div>
                    </div>
                    <div className="item">
                        <div className="label">新闻中心</div>
                        <div className="content">010-82887692</div>
                    </div>
                    <div className="item">
                        <div className="label">加入我们</div>
                        <div className="content">客服邮箱：customer@sqbj.com</div>
                    </div>
                    <div className="item">
                        <div className="label">合作入口</div>
                    </div>
                </div>
            </div>
            <div className="right">
                <div className="appBox">
                    <div className="app">
                        <div className="img" style={{ backgroundImage: `url(${require("./imgs/footer/app2.png")})` }} />
                        <div className="text">扫码下载半径智社区APP</div>
                    </div>
                    <div className="app">
                        <div className="img" style={{ backgroundImage: `url(${require("./imgs/footer/app1.png")})` }} />
                        <div className="text">扫码下载社区半径APP</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright" style={{ paddingBottom: 30 }}>
            <div>Copyright &copy; 2023 北京社区半径信息技术有限公司</div>
            <div>公司地址：北京市海淀区中关村南大街甲18号院1-4号楼16层D座16-16D</div>
            <div><a href="https://beian.miit.gov.cn" target="_blank" rel="noreferrer">京ICP备15003890号-1</a></div>
            <div>
                <a style={{ display: "flex", justifyContent: "center", alignItems: "center" }} rel="noreferrer" target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802032743">
                    <img src={require("./imgs/footer/icon1.png")} alt="" />&nbsp;<span>京公网安备11010802032743号</span>
                </a >
            </div>

        </div>
    </div>
}