import Banner from "../../components/Banner";
import Block from "../../components/Block";
import ImgBox from "../../components/ImgBox";
import Layout from "../../components/Layout";
import Page from "../../components/Page";

export default function News1() {
    return <Page className="pingPaiDongTai-news">
        <Banner url={require("./imgs/banner/bg2.png")} />
        <Block className="block">
            <div className="title">社区半径推免费智社区数字疫情防控助力社区打赢防疫战役</div>
            <div className="p">
                近日，社区半径宣布将推出智社区数字疫情防控模块，并免费提供给目前使用社区半径的1200家物业公司近2500个社区，助力社区工作者打赢这场艰巨而伟大的防疫之战。
            </div>
            <div className="p">
                据悉，智社区数字疫情防控功能主要是为坚守在疫情防控第一线的社区物业、街道、居委会人员等社区工作者提供的数字化的疫情信息管理系统。通过智社区的疫情防控功能模块，结合已有的智社区业主资料及门禁系统，社区管理方能够实时查看及管理小区业主的出行信息、每日体温监测信息、新返人员出行记录，对于有过疫区人员接触、居家隔离期不足或身体异常的人员进行重点关注，并通过数据报表的方式展示社区居民的防疫管理情况，极大的提升了社区防疫的管理效率，降低了潜在的疫情扩散隐患。
            </div>
            <Layout type="LR">
                <Layout.Left width="50%">
                    <ImgBox style={{ width: 361 }} rate={1.775623} url={require("./imgs/news1/2.png")} />
                </Layout.Left>
                <Layout.Right>
                    <ImgBox style={{ width: 361 }} rate={2.160665} url={require("./imgs/news1/1.png")} />
                </Layout.Right>
            </Layout>
            <div className="p">
                另一方面，通过微信小程序的相关模块，让业主快速实现出行调查、信息登记、出入通行证明、体温提报、远程或蓝牙开门等功能，并整合了高德地图的周边疫情，使得业主通过手机就可以快速提报个人信息、进行小区出入并实时掌握周边疫情信息。
            </div>
            <div className="p">
                社区半径CEO孙谦表示，虽然近年来国内很多城市都付出了很大的人力物力财力来建设智慧城市和智慧社区，但通过本次疫情大家发现，很多看似高大上的智慧系统，真正到了社区层面的管理上实际效用不大。究其原因，就是因为大部分项目重硬件轻软件，缺乏一个真正扎根于社区的用户系统，能获取到每一家每一户每一人的信息，所以到了需要的时候，大家发现还是要靠最最传统的纸笔来登记，还是要靠密集的人力去完成最基础的信息采集和统计。而这些耗费了大量的时间精力的工作，不但在疫情结束后无法进行有效的再利用，而且还存在着很大的管理隐患。
            </div>
            <Layout type="LR">
                <Layout.Left width="50%">
                    <ImgBox style={{ width: 361 }} rate={1.780555} url={require("./imgs/news1/3.png")} />
                </Layout.Left>
                <Layout.Right>
                    <ImgBox style={{ width: 361 }} rate={2.160665} url={require("./imgs/news1/1.png")} />
                </Layout.Right>
            </Layout>
            <div className="p">
                举个例子讲，很多社区都对于业主发放了纸质的出入通行证，登记了业主的姓名电话和住址。但这么一张小小的纸片，不但非常容易遗失，而且由于没有照片所以存在着很大的被冒用的可能性，且对于出入者是否执行了足够的隔离天数也无法认定，这就给疫情的管控带来很大的潜在风险。而社区半径的数字通行证，从整个逻辑上非常严格。首先必须要是物业系统内的认证业主才能够领取通行证，避免了出现外来人员冒领的情况；其次，在领取数字通行证前，业主需要先通过手机填写出行调查，包括近期出行的地点、是否与疫区人员近距离接触及返回的时间等。这些信息填写完成后，数字通行证会自动计算业主的隔离时间，社区管理方可以直接通过电子通行证看到出入业主是否符合隔离要求，对于未达到隔离期的业主进行管控。此外，由于数字通行证上有使用者的照片，客观上也避免了冒名顶替的情况。业主还可以通过手机进行远程或蓝牙开门，避免了直接与大门接触带来的风险。
            </div>
            <div className="p">
                而对于管理方而言，在后台可以直接查看所有业主提报的数据，对于具备疫情隐患的人员，系统会自动加以注释，管理方可设定为重点关注的人员，保持密切的关注与联系。疫情大数据系统，也将以可视化图表的方式展示社区疫情管理的方方面面。
            </div>
            <div className="p">
                孙谦认为，虽然近日来各地新感染病例的数据出现了下降的趋势，但是要看到，类似北上广深等大城市仍然面临着全面复工后返工人员带来的巨大隐患，所以疫情防控仍然刻不容缓。社区作为联防联控、群防群控的主战场，是阻断疫情扩散蔓延的最有效防线。利用信息化数字化的方式，能够有效提升社区疫情管理效率及质量，降低危险人员传播风险，减少一线人员的工作强度和压力。这次疫情形式虽然非常严峻，但危险中也蕴藏着机遇。物业也好街道也好，如何能够通过这次疫情，一方面通过信息化系统将社区居民的信息采集逐步完善，一方面也可以更好的推行自己的业主端APP或小程序或微信群，为后续的管理和运营做好铺垫。社区半径愿意与社会各界一道共同努力，利用自身在智慧社区领域的积淀与优势，为早日实现本次抗疫战役的胜利做出贡献。
            </div>
        </Block>
    </Page>
}