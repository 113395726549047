import Banner from "../../components/Banner";
import Block from "../../components/Block";
import ImgBox from "../../components/ImgBox";
import Page from "../../components/Page";

export default function News2023102602() {
    return <Page className="pingPaiDongTai-news">
        <Banner url={require("./imgs/banner/bg2.png")} />
        <Block noFlex className="block" style={{ width: 670, margin: "auto" }}>
            <div className="title">社区半径 CEO 胡小军拜访薛跃荣董事长</div>
            <ImgBox style={{ marginTop: 50 }} rate={0.749706} url={require("./imgs/news2023102602/1.jpeg")} />
            <div className="p">
                社区半径CEO胡小军前往总部位于深圳南山区金地总部，拜访薛跃荣董事长，参观金地智慧服务和金地空间科技，薛董事长介绍了企业情况和未来发展，双方深入交流数智化新方向，金地空间科技是一家集智慧建筑、智慧电梯、智慧设备等空间智慧化服务为一体的多元化科技型企业，为客户打造安全、便捷、健康、舒适的智慧空间。
            </div>
            <ImgBox style={{ marginTop: 30 }} rate={1.33359375} url={require("./imgs/news2023102602/2.jpeg")} />
            <ImgBox style={{ marginTop: 30 }} rate={0.749706} url={require("./imgs/news2023102602/3.jpeg")} />
            {/* <ImgBox style={{ marginTop: 30 }} rate={0.749706} url={require("./imgs/news2023102602/4.jpeg")} /> */}
            <ImgBox style={{ marginTop: 30 }} rate={1.33359375} url={require("./imgs/news2023102602/5.jpeg")} />
            <ImgBox style={{ marginTop: 30 }} rate={1.33359375} url={require("./imgs/news2023102602/6.jpeg")} />
            <ImgBox style={{ marginTop: 30 }} rate={1.33359375} url={require("./imgs/news2023102602/7.jpeg")} />
            <ImgBox style={{ marginTop: 30 }} rate={1.33359375} url={require("./imgs/news2023102602/8.jpeg")} />
            <ImgBox style={{ marginTop: 30 }} rate={1.3338558} url={require("./imgs/news2023102602/9.jpeg")} />
            <div className="p">
                社区半径做为国内较早提供专业化数智化产品的服务商之一，愿意与众多企业一起加快数智化发展的步伐，做改善居住环境和提升生活品质的重要推动者。
            </div>
        </Block>
    </Page>
}