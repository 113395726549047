import Banner from "../../components/Banner";
import Block from "../../components/Block";
import ImgBox from "../../components/ImgBox";
import Layout from "../../components/Layout";
import Page from "../../components/Page";

export default function News2() {
    return <Page className="pingPaiDongTai-news">
        <Banner url={require("./imgs/banner/bg2.png")} />
        <Block className="block">
            <div className="title">社区半径亮相深圳物博会 推出智慧社区全面解决方案</div>
            <div className="p">
                10月15日，由中国物业管理协会主办的2019中国国际物业管理产业博览会（以下简称“物博会”）在深圳市隆重开幕。中国物业管理协会会长沈建忠等物协
                领导及地方行业协会相关负责人，以及来自相关部门的人士出席了物博会开幕式及第五届中国物业管理创新发展论坛。
            </div>
            <Layout type="LR">
                <Layout.Left width="100%">
                    <ImgBox style={{ width: 415 }} rate={0.751807} url={require("./imgs/news2/1.png")} />
                </Layout.Left>
            </Layout>
            <div className="p">
                本届物博会以“科技赋能，发展共赢”为主题，展览面积2.25万平方米，参展企业达到189家。万科、碧桂园、彩生活、绿城、中海、龙湖、和泓等物业行业
                的头部企业均推出了大型展位。社区半径作为国内知名的智慧物业服务商，在本次展会上展示了最新的研发成果及成功案例，得到了众多行业认人士及参会观众
                的热烈关注。
            </div>
            <Layout type="LR">
                <Layout.Left width="100%">
                    <ImgBox style={{ width: 415 }} rate={0.751807} url={require("./imgs/news2/2.png")} />
                </Layout.Left>
            </Layout>
            <div className="p">
                在本次展会上，社区半径提出了“做国内领先的智慧社区解决方案提供商”的目标，将原有的智慧物业产品升级成为智慧社区整体解决方案。社区半径CEO
                孙谦认为：随着中国人口城市化比例的不断提升、城市流动人口数量的迅速增长、以及一线城市人口老龄化速度加快、无物业管理老旧社区不断增多等因素，社
                区的治理已经不能单纯的依赖于物业公司，而应由公安、街道、物业等多方共同治理，并充分发挥社区党员及热心居民的积极性，推动社区自治，打造新型社区
                治理模式。
            </div>
            <Layout type="LR">
                <Layout.Left width="100%">
                    <ImgBox style={{ width: 415 }} rate={0.751807} url={require("./imgs/news2/3.png")} />
                </Layout.Left>
            </Layout>
            <div className="p">
                在这种背景下，社区半径在原有的智慧物业及社区商业运营两大平台的基础上，打造创新的智慧街区平台，将街道的综治、党建、安监、政务、志愿者等工
                作，以及社区内的监控、道闸、人脸门禁、养老设备等IoT设备，通过信息化平台进行整合，并与物业管理平台及业主端APP/小程序打通，全面提升社区管理方
                的管理质量与效率，提高社区安全消防综治水平，降低人员设备管理成本，实现多方信息交互共享，创造社区党建引领及居民自治环境，并通过社区大数据中心
                提供数据分析与决策辅助，帮助政府部门打造利民利政、平安智慧的新型社区，助力物业企业实现降本增效、开源增收的经营目标。
            </div>
            <Layout type="LR">
                <Layout.Left width="100%">
                    <ImgBox style={{ width: 415 }} rate={0.751807} url={require("./imgs/news2/4.png")} />
                </Layout.Left>
            </Layout>
            <div className="p">
                目前，社区半径的智慧社区解决方案目前已经得到了多地政府相关部门，以及全球科技巨头公司阿里巴巴的认可。社区半径已顺利成为阿里巴巴ISV（服务
                供应商），与阿里云智慧城市团队在多个项目上展开了密切的合作，并在近期实现与阿里IoT物联网平台的完整对接，借助阿里云飞天平台在物联网设备接入、
                AI算法、数据处理、事件处理等方面的优势，结合社区半径多年来在社区物业及业主端应用层及展示层产品的经验及积累，社区半径推出的智慧社区解决方案，
                将技术优势与行业深度合二为一，充分满足包括住宅、园区、商写、老旧社区改造、三供一业等不同场景下的不动产物业管理及政府治理的需要，并可在未来与
                公安、城管、交通等部门的信息平台及智慧城市大脑实现数据对接，实现智慧城市重要的信息数据共享及点面结合上下贯通，为建设真正智能化信息化可实施能
                落地的智慧城市目标贡献力量。
            </div>
        </Block>
    </Page>
}