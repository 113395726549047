import React, { CSSProperties } from "react";
import FlexBox from "./FlexBox";

type Props = {
    noFlex?: boolean;
    className?: string;
    style?: CSSProperties;
    backgroundImage?: string | NodeRequire;
    children: React.ReactNode;
};

export default function Block(props: Props) {
    return <div className={props.className} style={{ ...props.style, backgroundImage: props.backgroundImage && `url(${props.backgroundImage})` }}>
        {props.noFlex ? props.children : <FlexBox>{props.children}</FlexBox>}
    </div>
}