import Banner from "../../components/Banner";
import Block from "../../components/Block";
import ImgBox from "../../components/ImgBox";
import Page from "../../components/Page";

export default function News2023102601() {
    return <Page className="pingPaiDongTai-news">
        <Banner url={require("./imgs/banner/bg2.png")} />
        <Block noFlex className="block" style={{ width: 670, margin: "auto" }}>
            <div className="title">2023中国物博会｜载誉而归 重踏征程</div>
            <ImgBox style={{ marginTop: 50 }} rate={0.666174} url={require("./imgs/news2023102601/1.jpeg")} />
            <div className="p">
                金秋时节,硕果飘香，2023年10月14日，在深圳市深圳会展中心8、9号馆组织召开的“2023中国国际物业管理产业物博会”于今日圆满结束，社区半径受邀参加此次物博会。诚挚感谢每一位嘉宾的到来，感谢大家对社区半径的关注、信任与支持！
            </div>
            <ImgBox style={{ marginTop: 30 }} rate={0.666174} url={require("./imgs/news2023102601/2.jpeg")} />
            <ImgBox style={{ marginTop: 30 }} rate={0.666174} url={require("./imgs/news2023102601/3.jpeg")} />
            <div className="p">
                为期三天的展会期间，社区半径的展位吸引了众多观众驻足。我们展示了以AI和数据分析为核心的智慧物业解决方案，为参展者提供了一个直观、生动的体验。通过我们的解决方案，能够提升物业管理的效率，优化服务质量，同时也能够增强业主的满意度和信任度。
            </div>
            <ImgBox style={{ marginTop: 30 }} rate={0.666174} url={require("./imgs/news2023102601/4.jpeg")} />
            <ImgBox style={{ marginTop: 30 }} rate={0.666174} url={require("./imgs/news2023102601/5.jpeg")} />
            <ImgBox style={{ marginTop: 30 }} rate={0.666174} url={require("./imgs/news2023102601/6.jpeg")} />
            <div className="p">
                在会议期间，社区半径公司还受邀参加了由中国物业管理协会主办的物业管理ESG论坛。在这个平台上，与会者共同探讨了如何在双碳政策目标下构建物业ESG生态，实现可持续发展。社区半径公司也分享了我们在推动绿色物业、能源管理和可持续运营方面的实践和经验。
            </div>
            <div className="p">
                此次参展不仅让社区半径有机会与行业内的专家、学者、企业家进行深入交流，也让我们收获了更多的市场信息和行业动态。我们了解到了行业内最新的发展趋势和创新理念，对于未来物业管理的方向有了更加清晰的认识。
            </div>
            <div className="p">
                这次参展不仅提高了公司的知名度和影响力，也为我们提供了向行业学习、与同行交流的机会。未来，社区半径将会继续积极参与到物业管理行业的发展中，持续创新和优化我们的产品和服务，为推动行业的进步和发展贡献力量。同时，我们也期待在今后的活动中，与更多的同行分享我们的经验和成果，共同探索物业管理的新时代。
            </div>
            <ImgBox style={{ marginTop: 30 }} rate={0.666174} url={require("./imgs/news2023102601/7.jpeg")} />
            <div className="p">
                最后，让我们再次感谢所有参与此次物博会的朋友们，让我们期待下一次的相聚。在新的征程中，让我们携手共进，共同为物业管理行业的繁荣和发展贡献力量！
            </div>
        </Block>
    </Page>
}