import { useParams } from "react-router-dom"
import Banner from "../../components/Banner"
import Block from "../../components/Block"
import BlockDescription from "../../components/BlockDescription"
import BlockTitle from "../../components/BlockTitle"
import FlexBox from "../../components/FlexBox"
import ImgBox from "../../components/ImgBox"
import Layout from "../../components/Layout"
import Page from "../../components/Page"
import News1 from "./news1"
import News2 from "./news2"
import News3 from "./news3"
import { pathMap } from "../../common"
import { jump2page } from "../api"
import News2023102601 from "./news2023102601"
import News2023102602 from "./news2023102602"

function Index() {
    return <Page className="pingPaiDongTai">
        <Banner url={require("./imgs/banner/bg.png")}>
            <Banner.Title>品牌动态</Banner.Title>
            <Banner.Description>提升管理效率，降低运营成本<br />增加运营利润！<br /></Banner.Description>
        </Banner>
        <Block className="block1">
            <BlockTitle>新闻中心</BlockTitle>
            <BlockDescription>打造便捷、高效、温暖的物业管理</BlockDescription>
            <Layout type="LR">
                <Layout.Left width={340} >
                    <ImgBox rate={0.6470588} url={require("./imgs/block1/2023102601.jpeg")} />
                </Layout.Left>
                <Layout.Right>
                    <div className="link" onClick={() => jump2page(`/${pathMap.pingPaiDongTai}/2023102601`)}>
                        <div className="title">2023 中国物博会|载誉而归 重踏征程</div>
                        <div className="description">2023年10月14日，在深圳市深圳会展中心8、9号馆组织召开的“2023中国国际物业管理产业物博会”于今日圆满结束，社区半径受邀参加此次物博会。诚挚感谢每一位嘉宾的到来，感谢大家对社区半径的关注、信任和支持！</div>
                    </div>
                </Layout.Right>
            </Layout>
            <Layout type="LR">
                <Layout.Left width={340} >
                    <ImgBox rate={0.6470588} url={require("./imgs/block1/2023102602.jpeg")} />
                </Layout.Left>
                <Layout.Right>
                    <div className="link" onClick={() => jump2page(`/${pathMap.pingPaiDongTai}/2023102602`)}>
                        <div className="title">社区半径 CEO 胡小军拜访薛跃荣董事长</div>
                        <div className="description">社区半径 CEO 胡小军前往总部位于深圳南山区金地总部，拜访薛跃荣董事长，参观金地智慧服务和金地空间科技。</div>
                    </div>
                </Layout.Right>
            </Layout>
            <Layout type="LR">
                <Layout.Left width={340} >
                    <ImgBox rate={0.6470588} url={require("./imgs/block1/1.png")} />
                </Layout.Left>
                <Layout.Right>
                    <div className="link" onClick={() => jump2page(`/${pathMap.pingPaiDongTai}/1`)}>
                        <div className="title">社区半径推免费智社区数字疫情防控助力社区打赢防疫战役</div>
                        <div className="description">近日，社区半径宣布将推出智社区数字疫情防控模块，并免费提供给目前使用社区半径的1200家物业公司近2500个社区，助力社区工作者打赢这场艰巨而伟大的防疫之战。</div>
                    </div>
                </Layout.Right>
            </Layout>
            <Layout type="LR">
                <Layout.Left width={340} >
                    <ImgBox rate={0.6470588} url={require("./imgs/block1/2.png")} />
                </Layout.Left>
                <Layout.Right>
                    <div className="link" onClick={() => jump2page(`/${pathMap.pingPaiDongTai}/2`)}>
                        <div className="title">社区半径亮相深圳物博会推出智慧社区全面解决方案</div>
                        <div className="description">近日，社区半径宣布将推出智社区数字疫情防控模块，并免费提供给目前使用社区半径的1200家物业公司近2500个社区，助力社区工作者打赢这场艰巨而伟大的防疫之战。</div>
                    </div>
                </Layout.Right>
            </Layout>
            <Layout type="LR">
                <Layout.Left width={340} >
                    <ImgBox rate={0.6470588} url={require("./imgs/block1/3.png")} />
                </Layout.Left>
                <Layout.Right>
                    <div className="link" onClick={() => jump2page(`/${pathMap.pingPaiDongTai}/3`)}>
                        <div className="title">社区半径签约上市物业集团 提供智慧物业云平台系统</div>
                        <div className="description">2019年9月4日，国内领先的智慧社区解决方案提供商-北京社区半径信息技术有限公司，与香港联交所上市物业集团-和泓服务集团，正式签署了“和泓服务.智社区云平台合作协议”。</div>
                    </div>
                </Layout.Right>
            </Layout>
        </Block>
        <Block className="block2" noFlex >
            <FlexBox>
                <BlockTitle>成功案例</BlockTitle>
                <BlockDescription>打造便捷、高效、温暖的物业管理</BlockDescription>
            </FlexBox>
            <div className="group hasBg" style={{ marginTop: 40 }}>
                <FlexBox>
                    <Layout type="LR">
                        <Layout type="LR">
                            <Layout.Left width={340} >
                                <ImgBox rate={0.6470588} url={require("./imgs/block2/1.png")} />
                            </Layout.Left>
                            <Layout.Right style={{ marginLeft: 60 }}>
                                <div className="title">北京.彩虹城</div>
                                <div className="subtitle">推进智慧社区建设，赋能物业，降本增效</div>
                                <div className="description">
                                    自2018年7月，北京市丰台区的彩虹城社区与社区半径开展了合作，通过社区半径的智慧社区解决方案，一方面将原有的物联网设备的管理和数据统一到社
                                    区半径智社区平台上，另一方面通过新的硬件设施的安装和实施，更好的实现
                                    了物业在成本控制、 管理效率和服务水准上的提升。
                                </div>
                            </Layout.Right>
                        </Layout>
                    </Layout>
                </FlexBox>
            </div>
            <div className="group">
                <FlexBox>
                    <Layout type="LR">
                        <Layout type="LR">
                            <Layout.Right style={{ marginRight: 60 }}>
                                <div className="title">长沙.梅溪四季</div>
                                <div className="subtitle">推进智慧社区建设，赋能物业，降本增效</div>
                                <div className="description">
                                    2016年2月，梅溪四季作为社区半径在长沙区域落地的样板项目正式启动。同时
                                    ，通过开展一系列基于社区主题的运营活动，社区半径持续为业主提供高性价比
                                    的产品与服务，基于物业、业主、周边商户形成和谐共生的社区生态环境。社区
                                    半径协助和泓物业完成整体社区包装及宣传工作，并积极引导业主通过社区半径
                                    APP完成门禁开启、物业报修、物业通知公告查看等服务，让业主真实体验智慧
                                    社区物业服务平台的便捷与高效。
                                </div>
                            </Layout.Right>
                            <Layout.Left width={340} >
                                <ImgBox rate={0.6470588} url={require("./imgs/block2/2.png")} />
                            </Layout.Left>
                        </Layout>
                    </Layout>
                </FlexBox>
            </div>
            <div className="group hasBg">
                <FlexBox>
                    <Layout type="LR">
                        <Layout type="LR">
                            <Layout.Left width={340} >
                                <ImgBox rate={0.6470588} url={require("./imgs/block2/3.png")} />
                            </Layout.Left>
                            <Layout.Right style={{ marginLeft: 60 }}>
                                <div className="title">华润置地.凤凰城</div>
                                <div className="subtitle">推进智慧社区建设，赋能物业，降本增效</div>
                                <div className="description">
                                    华润置地·凤凰城举办业主认证抽奖活动，更新业主信息，添加认证业主权限。
                                    活动得到广大业主的积极响应，上至七旬老人，下至20余岁的年轻人，纷纷参
                                    与，对智慧社区带来的便捷生活十分期待。社区公告直达业主手机、物业报修
                                    线上预约服务、智能手机在线即可开启单元门禁…… 这样“高大上”的智慧社区
                                    生活不再是电视里的场景。
                                </div>
                            </Layout.Right>
                        </Layout>
                    </Layout>
                </FlexBox>
            </div>
        </Block>
    </Page>
}

export default function PingPaiDongTai() {
    const { id } = useParams();
    if (id === "1") {
        return <News1 />
    } else if (id === "2") {
        return <News2 />
    } else if (id === "3") {
        return <News3 />
    } else if (id === "2023102601") {
        return <News2023102601 />
    } else if (id === "2023102602") {
        return <News2023102602 />
    } else {
        return <Index />
    }
}