import { Fragment, useState } from "react"
import Banner from "../../components/Banner"
import Block from "../../components/Block"
import BlockTitle from "../../components/BlockTitle"
import ImgBox from "../../components/ImgBox"
import ItemList from "../../components/ItemList"
import Layout from "../../components/Layout"
import Page from "../../components/Page"

export default function ZhiHuiWuGuan() {
    const [currIdx, setCurIdx] = useState(0);
    return <Page className="zhiHuiWuGuan">
        <Banner url="http://dev-public.img.oss.shequbanjing.com/property/application/6bb21a98-9872-48f5-9328-a9c0b18a2ca3.png">
            <Banner.Title>智慧物管解决方案</Banner.Title>
            <Banner.Description>基于统一、标准的物业主数据模型，自主研发数字化创新方案和产品，为物业<br />企业提供物业管理信息化、数字化整体解决方案。</Banner.Description>
        </Banner>
        <Block className="block1">
            <BlockTitle>智慧物管解决方案</BlockTitle>
            <ItemList className="a" >
                {[
                    {
                        iconUrl: "http://dev-public.img.oss.shequbanjing.com/property/application/4473b9a8-86f4-4df6-b403-2333bde2faa8.png",
                        title: "一键报事报修",
                    },
                    {
                        iconUrl: "http://dev-public.img.oss.shequbanjing.com/property/application/3f22e4d5-f518-4c8f-b9fd-215bfb8eccdb.png",
                        title: "抢/派单",
                    },
                    {
                        iconUrl: "http://dev-public.img.oss.shequbanjing.com/property/application/e3e09cb7-4c42-49aa-8cfc-92010d8dfffc.png",
                        title: "工单处理/缴费",
                    },
                    {
                        iconUrl: "http://dev-public.img.oss.shequbanjing.com/property/application/3184e2dc-4422-4083-a165-c57218e1c42f.png",
                        title: "客户在线评价",
                    },
                    {
                        iconUrl: "http://dev-public.img.oss.shequbanjing.com/property/application/59f626f6-717f-4bf2-ae2c-ce7dcfdbc4bc.png",
                        title: "数据留痕",
                    },
                ].map((o, idx) => <ItemList.Item key={idx}>
                    <ItemList.Icon url={o.iconUrl} />
                    <ItemList.Title>{o.title}</ItemList.Title>
                </ItemList.Item>)}
            </ItemList>
            <Layout type="LR" style={{ marginTop: 37 }} >
                <Layout.Left width={235}>
                    <ImgBox rate={1.472340} url="http://dev-public.img.oss.shequbanjing.com/property/application/ead70022-455d-443c-ac35-96b6d6b9d265.png" />
                </Layout.Left>
                <Layout.Right style={{ marginLeft: 20 }} >
                    <ItemList card className="b">
                        {[
                            {
                                icon: require("./imgs/block1/2/1.png"),
                                title: "多渠道接入",
                                description: <>400呼叫中心、小程序、业主<br />端app等</>,
                            },
                            {
                                icon: require("./imgs/block1/2/2.png"),
                                title: "工单数字化",
                                description: <>掌握情况，提高工作效率</>,
                            },
                            {
                                icon: require("./imgs/block1/2/3.png"),
                                title: "派单多样性",
                                description: <>智能化系统派单、人工派<br />单、工单池抢单</>,
                            },
                            {
                                icon: require("./imgs/block1/2/4.png"),
                                title: "工时可量化",
                                description: <>不同的维修事务定义不<br />同的工时</>,
                            },
                            {
                                icon: require("./imgs/block1/2/5.png"),
                                title: "过程透明化",
                                description: <>事前事后拍照、流程留痕、<br />收费信息公开</>,
                            },
                            {
                                icon: require("./imgs/block1/2/6.png"),
                                title: "风险预警制度",
                                description: <>配置升级上报提醒预警</>,
                            },
                        ].map((o, idx) => <ItemList.Item key={idx}>
                            <ItemList.Icon url={o.icon} />
                            <ItemList.Title>{o.title}</ItemList.Title>
                            <ItemList.Text>{o.description}</ItemList.Text>
                        </ItemList.Item>)}
                    </ItemList>
                </Layout.Right>
            </Layout>
        </Block>
        <Block className="block2">
            <BlockTitle>物业品质运营解决方案</BlockTitle>
            <Layout type="LCR" style={{ marginTop: 30 }}>
                <Layout.Left width={186} >
                    {[
                        "项目巡检", "品质检测", "设备管理"
                    ].map((o, idx) => <div key={idx} className={`item ${currIdx === idx ? "active" : ""}`.trim()} onClick={() => setCurIdx(idx)} >{o}</div>)}
                </Layout.Left>
                {[
                    {
                        center: <>
                            <div className="item">
                                <div className="itemTitle">标准化</div>
                                <div className="itemText">规范任务时间、地点、时限，业务作业标准化</div>
                            </div>
                            <div className="item">
                                <div className="itemTitle">无纸化</div>
                                <div className="itemText">已检待检实时展示，防止遗漏、作弊；支持GPS定位、拍照水印</div>
                            </div>
                            <div className="item">
                                <div className="itemTitle">移动化</div>
                                <div className="itemText">无网操作、数据实时上传</div>
                            </div>
                            <div className="item">
                                <div className="itemTitle">业务闭环</div>
                                <div className="itemText">巡检故障自动生成维修工单；任务评价：主管可通过工作圈查看巡检</div>
                            </div>
                        </>,
                        rightImg: require("./imgs/block2/img1.png"),
                    },
                    {
                        center: <>
                            <div className="item">
                                <div className="itemTitle">标准化</div>
                                <div className="itemText">品质部下发项目自检、城市公司品质月检和集团飞检，业务作业动线化、标准化。</div>
                            </div>
                            <div className="item">
                                <div className="itemTitle">公开化</div>
                                <div className="itemText">评审标准、检查方法和评分细则公开化、系统实时出分。</div>
                            </div>
                            <div className="item">
                                <div className="itemTitle">便捷化</div>
                                <div className="itemText">操作便捷、无网操作，实时上传结果。整改自动生成实现业务闭环。</div>
                            </div>
                        </>,
                        rightImg: require("./imgs/block2/img2.png"),
                    },
                    {
                        center: <>
                            <div className="item">
                                <div className="itemTitle">设备档案</div>
                                <div className="itemText">一物一码，巡检、维修、保养、维保合同等随时随地都可查看。</div>
                            </div>
                            <div className="item">
                                <div className="itemTitle">生命周期</div>
                                <div className="itemText">设备使用时长，全周期可查看。实时更新：巡检记录、保养记录实时更新。</div>
                            </div>
                            <div className="item">
                                <div className="itemTitle">实时更新</div>
                                <div className="itemText">巡检记录、保养记录实时更新。</div>
                            </div>
                            <div className="item">
                                <div className="itemTitle">巡检保养</div>
                                <div className="itemText">系统根据计划自动生成任务，提醒一线员工作业，确保作业无遗漏。</div>
                            </div>
                            <div className="item">
                                <div className="itemTitle">简易报表</div>
                                <div className="itemText">设备完好率、使用时长、功耗</div>
                            </div>
                        </>,
                        rightImg: require("./imgs/block2/img3.png"),
                    },
                ].map((o, idx) => currIdx === idx && <Fragment key={idx}>
                    <Layout.Center>{o.center}</Layout.Center>
                    <Layout.Right width={242} >
                        <ImgBox rate={1.519148936} url={o.rightImg} />
                    </Layout.Right>
                </Fragment>)}
            </Layout>
            <ItemList>
                {[
                    require("./imgs/block2/icon1.png"),
                    require("./imgs/block2/icon2.png"),
                    require("./imgs/block2/icon3.png"),
                    require("./imgs/block2/icon4.png"),
                ].map((o, idx) => <ItemList.Item key={idx}><ItemList.Icon url={o} /></ItemList.Item>)}
            </ItemList>
        </Block>
        <Block className="block3">
            <BlockTitle>资源管理解决方案</BlockTitle>
            <ItemList card style={{ marginTop: 35 }} >
                {[
                    {
                        img: "http://dev-public.img.oss.shequbanjing.com/property/application/3859beef-6d5a-4878-aacc-7b48aad25c09.png",
                        title: "客户资源管理",
                        description: "用户标签体系、客户画像；建立与房/车位的绑定管理；短信群发查询客户历史记录",
                    },
                    {
                        img: "http://dev-public.img.oss.shequbanjing.com/property/application/81a04843-3912-4ffd-ae6f-f92abec24b7d.png",
                        title: "房屋资源管理",
                        description: "房屋资源建档管理系统输出房屋标签；支持管理业主关系一键发送账单催费；查询房屋历史工单",
                    },
                    {
                        img: "http://dev-public.img.oss.shequbanjing.com/property/application/5a068b5e-edc6-40cd-bb3c-738d331a6706.png",
                        title: "车位资源管理",
                        description: <>建档车库车位<br />管理长租、短租车位<br />车位状态统计和管理，空置和收费管理</>,
                    },
                    {
                        img: "http://dev-public.img.oss.shequbanjing.com/property/application/0384f4b6-4dfb-4df8-84b0-6a0bfc7ebc93.png",
                        title: "设备资源管理",
                        description: <>设备台账管理<br />保养、巡检标准化<br />支持离线巡检<br />输出设备数据</>,
                    },
                ].map((o, idx) => <ItemList.Item key={idx}>
                    <ItemList.Img url={o.img} />
                    <ItemList.Title>{o.title}</ItemList.Title>
                    <ItemList.Text>{o.description}</ItemList.Text>
                </ItemList.Item>)}
            </ItemList>
        </Block>
        <Block className="block4" backgroundImage={require("./imgs/block4/bg.png")}>
            <BlockTitle>社区资源统一管理</BlockTitle>
            <ItemList card style={{ marginTop: 35 }} >
                {[
                    {
                        icon: "http://dev-public.img.oss.shequbanjing.com/property/application/e13c2e03-e56e-4d8f-ba41-9d48a004b4ae.png",
                        title: "固定资产管理",
                        description: "从入库、  领用、  迁移、  盘点、  到报废 处置，实现固定资产全流程闭环管理",
                    },
                    {
                        icon: "http://dev-public.img.oss.shequbanjing.com/property/application/74696e7f-0e22-464d-a62d-afbc65c0078e.png",
                        title: "仓库耗材管理",
                        description: "全方位管理物品入库、出库/领用、等全过程，帮助企业合理调度库存",
                    },
                    {
                        icon: "http://dev-public.img.oss.shequbanjing.com/property/application/bde66c75-a9c5-48f0-8888-b2f7a59ab704.png",
                        title: "成本控制",
                        description: "费用报销、合同管理全方位管理",
                    },
                    {
                        icon: "http://dev-public.img.oss.shequbanjing.com/property/application/04294283-99d3-4995-8cc0-50235c6851d0.png",
                        title: "在线审批流管理",
                        description: "一键审批和资产管理工作流程自定义设置，快捷 进行资产分发及调拨处置，规范公司管理流程",
                    },
                ].map((o, idx) => <ItemList.Item key={idx}>
                    <Layout type="LR">
                        <Layout.Left width={65}>
                            <div className="icon" style={{ backgroundImage: `url(${o.icon})` }} />
                        </Layout.Left>
                        <Layout.Right>
                            <div className="title">{o.title}</div>
                            <div className="description">{o.description}</div>
                        </Layout.Right>
                    </Layout>
                </ItemList.Item>)}
            </ItemList>
        </Block>
        <Block className="block5">
            <BlockTitle>物资管理解决方案</BlockTitle>
            <ImgBox
                style={{ marginTop: 50 }}
                rate={0.388506}
                url="http://dev-public.img.oss.shequbanjing.com/property/application/86561352-6cb1-4eef-a6cd-154d2cad0b68.png" />
        </Block>
    </Page>
}