import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { pathMap } from "../common";
import FlexBox from "./FlexBox";

export default function Header() {
    const navigate: any = useNavigate();
    const [visible, setVisible] = useState(false);
    return <div className="component-header">
        <FlexBox>
            <div className="component-header__rendered">
                <div className="logo" />
                <div className="navigation">
                    {[
                        {
                            path: pathMap.home,
                            text: "首页",
                            subnav: [],
                        },
                        {
                            path: pathMap.guanYuWoMen,
                            text: "关于我们",
                            subnav: [],
                        },
                        {
                            path: "",
                            text: "产品方案",
                            subnav: [
                                {
                                    className: "rightBorder bottomBorder",
                                    icon: require("./imgs/header/navIcon/1.png"),
                                    path: pathMap.yeCaiYiTiHuaJieJueFangAn,
                                    text: "业财一体化解决方案",
                                },
                                {
                                    className: "rightBorder bottomBorder",
                                    icon: require("./imgs/header/navIcon/2.png"),
                                    path: pathMap.zhiHuiWuGuanJieJueFangAn,
                                    text: "智慧物管解决方案",
                                },
                                {
                                    className: "bottomBorder",
                                    icon: require("./imgs/header/navIcon/3.png"),
                                    path: pathMap.renLiZiYuanPingTai,
                                    text: "人力资源平台",
                                },
                                {
                                    className: "rightBorder",
                                    icon: require("./imgs/header/navIcon/4.png"),
                                    path: pathMap.banGongXieTongFangAn,
                                    text: "办公协同方案",
                                },
                                {
                                    className: "rightBorder",
                                    icon: require("./imgs/header/navIcon/5.png"),
                                    path: pathMap.guanLiJiaShiCang,
                                    text: "管理驾驶舱",
                                },
                                {
                                    className: "",
                                    icon: require("./imgs/header/navIcon/6.png"),
                                    path: pathMap.wuLianWangJieJueFangAn,
                                    text: "物联网解决方案",
                                },
                            ],
                        },
                        {
                            path: pathMap.pingPaiDongTai,
                            text: "品牌动态",
                            subnav: [],
                        },
                        {
                            path: pathMap.shengTaiYuHeZuo,
                            text: "生态与合作",
                            subnav: [],
                        },
                    ].map((m, idx) => {
                        const active = window.location.pathname.indexOf(m.path) > -1;
                        if (m.subnav.length > 0) {
                            return <Fragment key={idx}>
                                <div className="nav" onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)} >
                                    <div className={`hasSubnav ${visible ? "open" : ""}`.trim()}>产品方案</div>
                                </div>
                                <div className="subnavBox" style={{ display: visible ? undefined : "none" }} onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)} >
                                    {m.subnav.map((n, subidx) => <div key={subidx} className={`subnav ${n.className}`.trim()} onClick={() => navigate(`/${n.path}`)} >
                                        <div className="subnav__rendered">
                                            <div className="icon" style={{ backgroundImage: `url(${n.icon})` }} />
                                            <div className="text">{n.text}</div>
                                        </div>
                                    </div>)}
                                </div>
                            </Fragment>
                        } else {
                            return <Link key={idx} className={`nav ${active ? "active" : ""}`.trim()} to={`/${m.path}`} >{m.text}</Link>
                        }
                    })}
                </div>
                <div className="phone">010-82887692</div>
            </div>
        </FlexBox>
    </div>
}

