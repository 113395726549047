import Banner from "../../components/Banner"
import Block from "../../components/Block"
import BlockDescription from "../../components/BlockDescription"
import BlockTitle from "../../components/BlockTitle"
import ImgBox from "../../components/ImgBox"
import Page from "../../components/Page"

export default function GuanYuWoMen() {
    return <Page className="guanYuWoMen">
        <Banner url="http://dev-public.img.oss.shequbanjing.com/property/application/0886d259-a91e-492d-b9a4-f9ac10353bb9.png">
            <Banner.Title>关于我们</Banner.Title>
            <Banner.Description>提升管理效率，降低运营成本<br />增加运营利润！</Banner.Description>
        </Banner>
        <Block className="block1">
            <BlockTitle>公司介绍</BlockTitle>
            <BlockDescription>打造便捷、高效、温暖的物业管理</BlockDescription>
            <div className="description">
                <div>
                    北京社区半径信息技术有限公司成立于2014年10月10日，由多位互联网及物业行业资深高管所创立。社区半径通过物联网、大数据、 云计算、Al人工智能等先进的理念、技术及产品，提供基于智慧物联的整体解决方案，面向物业、地产、园区、街道、政府等企事业单位，提供智慧化改造及升级。为合伙伙伴提供智慧、安全、高效开放的智慧管理平台。
                </div>
                <div style={{ marginTop: 25 }}>
                    公司拥有具备自主知识产权的智慧社区物业管理平台、智能物联网平台、智慧社区业主平台物联网智能门禁等多项产品。拥有专利5 项，软件著作权30余项，2016年获评中关村高新技术企业，国家高新技术企业，2018年通过国际认证软件成熟能力CMMI 3级，系统集成资质 4级。2019年，社区半径参与了多项国内大型智慧社区项目建设，以其专业优质的技术能力与服务态度，成为了广受客户信赖的智慧社区解决方案提供商。
                </div>
            </div>
            <ImgBox style={{ marginTop: 40 }} rate={0.263747} url="http://dev-public.img.oss.shequbanjing.com/property/application/5be69992-151c-484a-bfe5-51f612855e41.png" />
            {/* <BlockTitle style={{ marginTop: 60 }}>我们的核心团队</BlockTitle>
            <BlockDescription>社区半径核心团队由拥有丰富专业知识和领导力的高管组成，拥有打造一流企业的强大决心和坚实的实际操作力<br />凭借强大的市场传播能力，推动卓越的企业品牌和形象形成有力的市场支持</BlockDescription> */}
        </Block>
        {/* <Block className="block2">
            <ItemList card >
                {[
                    {
                        bgUrl: require("./imgs/block2/1.png"),
                        title: "胡小军 CEO",
                        description: "原龙湖智慧服务集团首席信息官，中国数字化杰出CIO",
                    },
                    {
                        bgUrl: require("./imgs/block2/2.png"),
                        title: "安鑫 副总经理",
                        description: "社区半径副总经理兼呼和浩特市分公司总经理",
                    },
                ].map((o, idx) => <ItemList.Item key={idx} backgroundImage={o.bgUrl}>
                    <div className="box">
                        <ItemList.Title>{o.title}</ItemList.Title>
                        <ItemList.Text>{o.description}</ItemList.Text>
                    </div>
                </ItemList.Item>)}
            </ItemList>
        </Block> */}
        <Block className="block3">
            <BlockTitle>服务客户</BlockTitle>
            <BlockDescription>服务于数百家物业，通过提供软硬件、技术方案落地培训等方位的支持，助力合作伙伴成功进行智慧社区转型。</BlockDescription>
            <ImgBox
                style={{ marginTop: 30 }}
                rate={0.503055}
                url="https://public.img.oss.shequbanjing.com/property/application/90491e65-881b-4446-8781-6a4dc4a55ed0.png" />
        </Block>
    </Page>
}