import Banner from "../../components/Banner"
import Block from "../../components/Block"
import BlockDescription from "../../components/BlockDescription"
import BlockTitle from "../../components/BlockTitle"
import ImgBox from "../../components/ImgBox"
import Layout from "../../components/Layout"
import Page from "../../components/Page"

export default function BanGongXieTong() {
    return <Page className="page banGongXieTong">
        <Banner url="https://dev-public.img.oss.shequbanjing.com/property/application/afcdb532-f561-41d0-a257-ee3ce9e9d55f.png">
            <Banner.Title>办公协同方案</Banner.Title>
            <Banner.Description>内置流程引擎，提供会议、合同、人事、门户、文档等功能，满足各类管理场景所需，集<br />移动化、智能化、实现全程数字化办公，工作效率更高。</Banner.Description>
        </Banner>
        <Block className="block1">
            <BlockTitle>审批</BlockTitle>
            <BlockDescription>强大的审批流程引擎，支持了业务高效流转，可视化的流程设计界面更易操作，轻松创建和维护审批流程，满足各业务需求，移动审批支持组织内的所有审批业务自动分类推送，轻松完成审批工作。</BlockDescription>
            <Layout type="LR">
                <Layout.Left width={330} >
                    <ImgBox rate={1.063636} url={require("./imgs/1.png")} />
                </Layout.Left>
                <Layout.Right style={{ marginLeft: 46 }}>
                    <div className="hasColon">自定义审批流程，实现跨组织审批：</div>
                    <div>自动识别发起人组织架构，统一设定的审批流程适用多场景业务</div>
                    <div className="hasColon">流程节点类型丰富，配置灵活：</div>
                    <div>支持审批、抄送节点设定，支持参与人多种身份类型设定，支持多种审批方式设定</div>
                    <div className="hasColon">自定义表单，覆盖多部门审批业务场景：</div>
                    <div>支持丰富的字段属性，简单拖拽即可完成表单设置，满足各部门审批需求</div>
                    <div className="hasColon">多端消息通知，及时高效处理：</div>
                    <div>移动审批可以随时随地发起申请、操作审批，业务协同更便捷</div>
                </Layout.Right>
            </Layout>
        </Block>
        <Block className="block2">
            <BlockTitle>合同</BlockTitle>
            <BlockDescription>有效管理收入、支出业务，以合同为基础，贯穿业务、财务、法务，通过审批等共享服务能力，实现合同签订、变更、履约、结算、收付款的全生命周期数字化管理。</BlockDescription>
            <Layout type="LR" >
                <Layout.Right style={{ marginRight: 46 }}>
                    <div className="hasColon">丰富的合同类型，赋能各业务场景：</div>
                    <div>物业服务合同、多种经营合同、采购合同、外包合同、维保合同、公建合同…</div>
                    <div className="hasColon">合同模板标准化管理，台账清晰：</div>
                    <div>规范合同模板、自定义审批流程、内外部协同提高效率</div>
                    <div className="hasColon">供应商履约评价，全过程监管透明：</div>
                    <div>高效管理合同业务履约全过程，从细节把控，全方位追踪合</div>
                    <div className="hasColon">合同收入支出，费用结算，到期预警提醒：</div>
                    <div>收款付款分期管理、费用结算台账到期提醒</div>
                </Layout.Right>
                <Layout.Left width={330} >
                    <ImgBox rate={1.063636} url={require("./imgs/2.png")} />
                </Layout.Left>
            </Layout>
        </Block>
        <Block className="block3">
            <BlockTitle>工作任务</BlockTitle>
            <BlockDescription>以高效协同办公为目标，参考工作模板批量创建，也支持随时随地在移动端交办临时任务，执行任务；工作目标清晰明确，方便掌握工作进展。</BlockDescription>
            <Layout type="LR">
                <Layout.Left width={330} >
                    <ImgBox rate={1.063636} url={require("./imgs/3.png")} />
                </Layout.Left>
                <Layout.Right style={{ marginLeft: 46 }}>
                    <div className="hasColon">依据行业规范，预置常规工作模板：</div>
                    <div>系统预置四大专业部门工作模板，轻松创建每年的月度任务目标</div>
                    <div className="hasColon">自定义任务周期，批量一次性生成：</div>
                    <div>任务周期灵活可配，批量创建，自动派发，省时高效</div>
                    <div className="hasColon">临时交办任务有迹可循，进度可控：</div>
                    <div>多端支持创建临时任务，随时掌握工作进度</div>
                    <div className="hasColon">个人日程备忘提醒，工作有序规划：</div>
                    <div>添加日程备忘，自定义设定标签，管理工作计划更便捷</div>
                </Layout.Right>
            </Layout>
        </Block>
        <Block className="block4">
            <BlockTitle>会议</BlockTitle>
            <BlockDescription>通过会议室管理、会议预定及会议提醒机制，使得会议管理更为高效</BlockDescription>
            <Layout type="LR">
                <Layout.Right style={{ marginRight: 46 }}>
                    <div className="hasColon">合理整合会议室资源，预定高效有序：</div>
                    <div>维护会议室场地，以会议室视角呈现不同进度会议，预约更便捷</div>
                    <div className="hasColon">会议议题提前周知，会议附件及时传阅：</div>
                    <div>创建会议设定主讲人、参会人，提前了解主题和附件内容，提高会议效率</div>
                    <div className="hasColon">预约及时提醒，会议时间不冲突：</div>
                    <div>个人会议看板清晰呈现，关联日程安排及时提醒</div>
                </Layout.Right>
                <Layout.Left width={330} >
                    <ImgBox rate={1.063636} url={require("./imgs/4.png")} />
                </Layout.Left>
            </Layout>
        </Block>
    </Page>
}