import Banner from "../components/Banner"
import Block from "../components/Block"
import BlockDescription from "../components/BlockDescription"
import BlockTitle from "../components/BlockTitle"
import ImgBox from "../components/ImgBox"
import Page from "../components/Page"

export default function YeCaiYiTiHua() {
    return <Page className="yeCaiYiTiHua">
        <Banner url="http://dev-public.img.oss.shequbanjing.com/property/application/06970eaf-81bc-4a4c-a5e3-974822f34ea2.png">
            <Banner.Title>业财一体化解决方案</Banner.Title>
            <Banner.Description>业财一体化方案主要为收入管理平台，以收费系统为核心、涵盖物业费周期<br />记账、减免、线上支付、开票、推凭等功能。</Banner.Description>
        </Banner>
        <Block className="block1">
            <BlockTitle>业财一体化</BlockTitle>
            <BlockDescription>以收费为核心、涵盖物业费周期记账、减免、线上支付、开票、推凭等功能。通过一体化平台管控物业收入， 使管<br />理者能清晰查看所有收入发生状态，从源头把控财务流程，降低财务风险 ，助力物业数智化转型。</BlockDescription>
            <div className="layoutLR">
                <div className="left" style={{ backgroundImage: "url(http://dev-public.img.oss.shequbanjing.com/property/application/86034257-4681-4d98-b596-90a28436f287.png)" }} />
                <div className="right">
                    <div className="group">
                        <div className="text1">线上缴费：</div>
                        <div className="text2">小程序缴费、  APP线上缴费、  移动POS、  短信催缴、  当面付，可对账。</div>
                    </div>
                    <div className="group">
                        <div className="text1">税控一体化：</div>
                        <div className="text2">电子发票、  发票/电子收据打印、  邮件/短信发送发票。</div>
                    </div>
                    <div className="group">
                        <div className="text1">停车一体化：</div>
                        <div className="text2">停车系统业财一体，数据互通。</div>
                    </div>
                    <div className="group">
                        <div className="text1">核算一体化：</div>
                        <div className="text2">自动生成凭证。</div>
                    </div>
                    <div className="group">
                        <div className="text1">统计分析：</div>
                        <div className="text2">应收汇总、欠费统计、收款汇总分析、预收账龄表、应收账龄表、收缴率分析、管家收费率报表、清欠统计。

                        </div>
                    </div>
                </div>
            </div>
        </Block>
        <Block className="block2">
            <div className="layoutLR">
                <div className="left">
                    <div className="textBox">
                        <div className="text1">优势</div>
                        <div className="text2">
                            统一收费过程及管理规范<br />
                            降低催缴成本，有效提升收缴率<br />
                            构建财务监管体系、减少收入漏洞<br />
                            以结果为导向，进行精细化的过程管理<br />
                            提高服务质量、提升物业收入
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="imgBox" style={{ backgroundImage: "url(http://dev-public.img.oss.shequbanjing.com/property/application/0245bfcd-338b-4a66-ad89-8480ebec4b0f.png)" }} />
                </div>
            </div>
        </Block>
        <Block className="block3">
            <ImgBox
                rate={0.473953}
                url="http://dev-public.img.oss.shequbanjing.com/property/application/a2eeb739-ae9f-4c77-8c8f-2c62694c9df0.png" />
        </Block>
        <Block className="block4">
            <BlockTitle>税控一体化</BlockTitle>
            <div className="layoutLR">
                <div className="left">
                    <div className="title">优势</div>
                    <div className="description">提升效率、  减少损失、  降低风险、 提升品牌、  节约成本</div>
                    <div className="cards">
                        <div className="card" style={{ backgroundImage: "url(https://public.img.oss.shequbanjing.com/property/application/5600380d-de5d-483d-9351-ebc50a017a39.png)" }}>
                            <div className="text1">核算一体化</div>
                            <div className="text2">自动推凭<br />自动对接</div>
                        </div>
                        <div className="card" style={{ backgroundImage: "url(https://public.img.oss.shequbanjing.com/property/application/cd66dbe7-d7d8-4c91-9a7c-cf772960f433.png" }}>
                            <div className="text1">电子发票</div>
                            <div className="text2">自动生成电子发票<br />减少票据管理风险<br />降低票据管理成本</div>
                        </div>
                        <div className="card" style={{ backgroundImage: "url(https://public.img.oss.shequbanjing.com/property/application/640f1a10-b5a4-4aaa-98db-b5671814004f.png)" }}>
                            <div className="text1">多种渠道下载电子发票</div>
                            <div className="text2">业主端APP<br />微信公众号<br />电子邮件等方式</div>
                        </div>
                        <div className="card" style={{ backgroundImage: "url(https://public.img.oss.shequbanjing.com/property/application/773746bd-3750-4890-aa3a-9eea8350b807.png)" }}>
                            <div className="text1">电子收据</div>
                            <div className="text2">降低票据成本<br />无纸化<br />安全<br />降低管理成本</div>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <ImgBox
                        rate={1.730539}
                        url="https://public.img.oss.shequbanjing.com/property/application/e61404db-76d5-45fa-b4df-4f41dd931663.png" />
                </div>
            </div>
        </Block>
    </Page>
}