import Banner from "../../components/Banner"
import Block from "../../components/Block"
import BlockDescription from "../../components/BlockDescription"
import BlockTitle from "../../components/BlockTitle"
import ImgBox from "../../components/ImgBox"
import Layout from "../../components/Layout"
import Page from "../../components/Page"

export default function GuanLiJiaShiCang() {
    return <Page className="guanLiJiaShiCang">
        <Banner url="https://dev-public.img.oss.shequbanjing.com/property/application/bd718ff6-3bef-4c58-8ebe-f90382e3da70.png">
            <Banner.Title>管理驾驶舱</Banner.Title>
            <Banner.Description>通过数据入湖、各系统数据拉通、统一标准与算法、拉通业务口径，实现了更精准、更及时的数据<br />分析，消灭数据核对、集团地区数据不拉通、业务逻辑不交圈等痛点</Banner.Description>
        </Banner>
        <Block className="block1">
            <BlockTitle>管理驾驶舱</BlockTitle>
            <BlockDescription>智能物联解决方案从社区人行、车行、品质管理、设施设备监测、智能水电表、智能门禁、业主居家智慧服务等方面实现统一管理、数据同步、事前预警等功能，帮助企业提升运营管理效率，降低管理成本和系统风险，同时提升社区业主智慧化生活感受。</BlockDescription>
            <Layout type="LR" style={{ marginTop: 40 }}>
                <Layout.Left width="50%">
                    <ImgBox style={{ width: 414 }} rate={0.526570} url="http://dev-public.img.oss.shequbanjing.com/property/application/8cef708e-21b0-4c5c-b98a-0b1e5c811c16.png" />
                </Layout.Left>
                <Layout.Right>
                    <div className="title">核心价值</div>
                    <div className="text">
                        设备在线，设施设备线上化、数据化，实时 反馈，数据采集，可视化管理
                        数据互联，跨品牌、跨设备数据打通，多系 统联动，社区生活场景智能化
                        智慧体验，人性化交互，实时反馈业主；物业多渠道信息发布，构建更良性业主关系管理效率，
                        设备报警与故障自动派单、及时处理，提升物业管理效率赋能行业，降低高额的信息流转成本，
                        全新的信息视角助力决策机制的变革，人机协同，设备实时在线监控
                    </div>
                </Layout.Right>
            </Layout>
        </Block>
        <Block className="block2">
            <BlockTitle>大数据平台框架</BlockTitle>
            <BlockDescription>数据中台，针对集团高管及各相关部门、地区公司管理层，对企业、项目经营数据的分析需求，为核心用户群打造了覆盖基础和增值、涵盖经营分析全部场景的统一分析平台以及日常线上标准数据看板。</BlockDescription>
            <ImgBox style={{ marginTop: 40 }} rate={0.505606} url="http://dev-public.img.oss.shequbanjing.com/property/application/ce84d928-9b1c-4cdf-bfc2-bcccb81f778a.png" />
        </Block>
    </Page>
}